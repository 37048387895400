import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [2,3];

export const dictionary = {
		"/": [~5],
		"/(auth)/admin": [6,[2]],
		"/(auth)/admin/investors": [~7,[2]],
		"/(auth)/admin/investors/[id]": [~8,[2]],
		"/(auth)/admin/live-campaigns": [~9,[2]],
		"/(auth)/admin/logs": [~10,[2]],
		"/(auth)/admin/stats": [~11,[2]],
		"/(auth)/campaigns": [~23],
		"/(auth)/campaign/[id=int]": [~12,[3],[4]],
		"/(auth)/campaign/[id=int]/admin": [~14,[3],[4]],
		"/(auth)/campaign/[id=int]/beloningen": [~15,[3],[4]],
		"/(auth)/campaign/[id=int]/community": [~16,[3],[4]],
		"/(auth)/campaign/[id=int]/community/terugbetalingen": [~17,[3],[4]],
		"/(auth)/campaign/[id=int]/doel": [~18,[3],[4]],
		"/(auth)/campaign/[id=int]/gegevens": [~19,[3],[4]],
		"/(auth)/campaign/[id=int]/support": [~20,[3],[4]],
		"/(auth)/campaign/[id=int]/toezeggingen": [~21,[3],[4]],
		"/(auth)/campaign/[id=int]/verhaal": [~22,[3],[4]],
		"/(auth)/campaign/[id=int]/[...fallback]": [13,[3],[4]],
		"/(auth)/design": [24],
		"/login": [~25],
		"/logout": [~26],
		"/passwordreset": [~27],
		"/terugbetaalschema": [28],
		"/toezeggingen/[id]": [~29]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';